.alg-dropdown {
  &.p-dropdown {
    border: none;
    border-radius: .5rem;
    width: 100%;
    font-size: 1.2rem;

    .p-dropdown-label {
      padding-left: 2em;
      font-size: 1.2rem;
      background-color: rgba(0, 0, 0, .05);
      border: none;
      box-shadow: 0 0.1rem #c9cfd7 inset;
    }

    .p-dropdown-trigger {
      right: unset;
      left: 0;
      position: absolute;
      height: 100%;
    }
  }
}
