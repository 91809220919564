.alg-tree-nav {
  &.p-tree {
    width: 100%;
    background-color: var(--nav-bg-color) !important;
    border: none;
    padding: 0;
    border-radius: 0;
  }

  .p-tree-empty-message {
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
  }

  .p-tree-container {
    padding: 0 !important;
    min-height: 60px !important;
    overflow: hidden;
  }

  .p-tree-toggler {
    display: none !important;
  }

  .p-checkbox {
    display: none !important;
  }

  .p-treenode-label {
    width: 100%;
    color: #fff !important;
    padding: 0 !important;
  }

  .p-treenode {
    padding: 0 !important;
  }

  .p-treenode-content {
    border: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content:focus {
    outline: none;
    box-shadow: none;
  }

  .p-treenode-icon {
    margin: 0 !important;
  }

  :host i .fa {
    font-size: 1.2rem;
  }

  .p-treenode-children {
    padding-left: 3rem !important;

    background-color: var(--nav-bg-child-color);

    .node-item-content {
      border-bottom-color: var(--nav-child-border-color) !important;
    }

    .mask-branch {
      background-color: var(--nav-bg-child-color) !important;
    }
  }

  .p-treenode-children {
    border-left: .1rem solid var(--nav-border-color);
  }

  .p-tree-container
  > p-treenode
  > .p-treenode
  > .p-treenode-children {
    border-left: .1rem solid transparent;
  }

  .p-tree-container
  > p-treenode
  > .p-treenode
  > .p-treenode-children
  > p-treenode:last-child
  > .p-treenode
  > .p-treenode-content
  .node-tree-item
  .node-item-branch
  .bottom-branch {
    border-left: .1rem solid var(--nav-branch-border-color);
  }

  .p-tree-container
  > p-treenode:last-child
  > .p-treenode
  > .p-treenode-children
  > p-treenode:last-child
  > .p-treenode
  > .p-treenode-content
  .node-tree-item
  .node-item-branch
  .bottom-branch {
    border-left: none;
  }

  p-treenode:last-child
  > .p-treenode
  > .p-treenode-content
  .node-tree-item
  .node-item-branch
  .bottom-branch {
    border-right: none;
    border-left: none;
  }

  p-treenode:first-child
  > .p-treenode
  > .p-treenode-content
  .node-tree-item
  .node-item-branch
  .top-branch {
    border-right: none;
  }

  .p-treenode-children .node-tree-item {
    .node-item-branch {
      .top-branch {
        border-left: .1rem solid var(--nav-branch-border-color);
        border-bottom: .1rem solid var(--nav-branch-border-color);
        border-right: none;
      }

      .bottom-branch {
        border-left: .1rem solid var(--nav-branch-border-color);
        border-right: none;
      }
    }
  }

  .p-treenode-children .node-tree-item.folder.expanded .node-item-branch .bottom-branch {
    border-right: .1rem solid var(--nav-branch-border-color);
  }

  .p-treenode-children .node-tree-item .node-item-branch .top-branch {
    border-right: none !important;
  }

  .p-treenode-children .node-tree-item .node-item-branch .bottom-branch {
    border-right: none !important;
  }

  .p-treenode-content {

    padding: 0 !important;

    &:active {
      .node-tree-item .node-item-content {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        background-color: rgba(255, 255, 255, 0.2);
        color: white;

        .node-select {
          color: #fff;
          background-color: #f5a623;
          border-radius: 9rem;
        }
      }
    }

    .node-tree-item {
      width: 100%;
      display: block;
      flex-direction: row;
      align-items: center;
      min-height: 4.5rem;
      position: relative;
      cursor: pointer;

      &.locked {
        .node-label-title {
          opacity: .8;
        }
      }

      .node-item-branch {
        display: block;
        width: 3.2rem;
        min-height: 4.5rem;
        z-index: 10;

        .top-branch {
          display: block;
          border-right: .1rem solid var(--nav-branch-border-color);
          min-height: 2.25rem;
          position: relative;

          .mask-branch {
            display: block;
            position: absolute;
            right: -.1rem;
            width: 1.3333rem;
            bottom: -.1rem;
            height: 1.25rem;
            background-color: var(--nav-bg-color);
          }
        }

        .bottom-branch {
          display: block;
          border-right: .1rem solid var(--nav-branch-border-color);
          min-height: 2.25rem;
          position: relative;
          z-index: 1;

          .mask-branch {
            display: block;
            position: absolute;
            right: -.1rem;
            width: 0.1667rem;
            top: 0;
            height: 1.2rem;
            background-color: var(--nav-bg-color);
          }
        }
      }

      .node-item-content {
        padding: 0 0.25rem;
        position: absolute;
        display: block;
        border-bottom: .1rem solid var(--nav-border-secondary-color);
        padding-right: 1.6667rem;
        top: 0;
        bottom: 0;
        left: 1.25rem;
        right: 0;

        .node-select {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 1.25rem;
          margin-left: 0.4167rem;
          text-align: center;
          position: absolute;
          top: 50%;
          margin-top: -1.25rem;
          color: #f5a623;
          z-index: 11;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            line-height: 2.5rem;
          }

          alg-score-ring {
            width: 100%;
            height: 100%;
          }
        }

        &.node-bottom-radius {
          border-bottom-right-radius: 0.5rem;
        }

        .node-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1.1667rem;
        }

        .fade {
          opacity: 50%;
        }

        .node-label {
          top: 0;
          bottom: 0;
          left: 4.1667rem;
          right: 0;
          position: absolute;
          display: flex;
          align-items: center;
          padding-right: 1.6667rem;

          .label-container {
            display: flex;
            align-items: center;
            flex: 1;
            max-width: 100%;
            height: 100%;
          }

          .node-state {
            display: flex;
            align-items: center;
            margin-left: auto;
            height: 100%;

            i {
              margin-left: 0.5rem;
              font-size: 1.2rem;
            }
          }

          .node-label-title {
            font-family: var(--font-family);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 1rem;
            font-size: 1.2rem;
            font-weight: 700;
          }

          alg-skill-progress {
            position: absolute;
            left: 0;
            right: 1.25rem;
            bottom: 0;
          }
        }
      }

      &.expanded .folder-branch {
        position: relative;
      }

      &.current {
        .top-branch {
          z-index: 1;

          .mask-branch {
            background: none;
          }
        }

        .node-item-content {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          background-color: var(--base-bk-color);
          color: var(--nav-text-color);

          &:before,
          &:after {
            content: " ";
            background-image: url("/assets/images/corner-white.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 0;
            top: -1rem;
          }

          &:after {
            top: auto;
            bottom: -1rem;
            background-position: bottom;
          }

          .node-select {
            color: #fff;
            background-color: #f5a623;
            border-radius: 9rem;
          }
        }
      }

    }
  }
}
