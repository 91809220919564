@import "src/colors.scss";

.alg-language-picker-dropdown {
  &.base {
    &.p-dropdown {
      .p-dropdown-label {
        color: var(--base-color);
      }

      .p-dropdown-item {
        &:not(.p-highlight) {
          color: var(--base-text-color);
          background-color: white;
        }
      }
    }
  }

  &.p-dropdown {
    background-color: transparent;
    border: none;
    width: 4.1rem;

    &:hover {
      background-color: var(--hover-mask);
    }

    .p-dropdown-label {
      text-transform: uppercase;
      text-overflow: clip;
      color: white;
    }

    .p-dropdown-panel {
      background-color: var(--nav-bk-color);

      .p-highlight {
        background-color: var(--base-color);
      }
    }

    .p-dropdown-item {
      text-transform: uppercase;
      font-size: 1rem;
      color: white;

      &:not(.p-highlight):not(.p-disabled):hover {
        color: white;
        background: var(--base-color-hover);
      }
    }
  }
}
