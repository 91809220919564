@import 'src/colors.scss';

.alg-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  margin-right: 1.25rem;
  margin-bottom: 2.5rem;
  color: var(--base-text-color);

  &.my-groups {
    flex-direction: column;
    align-items: start;
  }
}

.alg-page-header-title {
  font-size: 2.6rem;
  margin-bottom: 1rem;
  color: #4A4A4A;
}

.alg-page-header-subtitle {
  font-size: 1.2rem;
}
