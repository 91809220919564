@import 'src/colors';
@import 'src/geometry';

.alg-propagation-edit-menu {
  background: var(--base-color);

  &:after {
    border-bottom-color: var(--base-color);
  }

  &.p-overlaypanel-flipped {
    &:after {
      border-top-color: var(--base-color);
    }
  }

  &.p-overlaypanel {
    border-radius: $border-radius;
  }

  .p-overlaypanel-content {
    padding: 0;
    border-radius: 0.5rem;
    overflow: hidden;
  }
}
