@import 'src/colors';
@import 'src/geometry';

.tab-left-nav {
  &.p-tabview {
    padding: 0;
    background-color: var(--base-bk-color);
    border-radius: 0;

    &.dark {
      background-color: #4E4E4E;
    }

    &.p-tabview-top .p-tabview-nav li:focus {
      box-shadow: none;
    }

    ul.p-tabview-nav {
      display: flex;
      height: 5rem;

      > li {
        flex: 1;
        border: none;
        outline: none;
        margin: 0;
        top: 0;
        background-color: var(--nav-bg-secondary-color);
        border-radius: 0;
        position: relative;
        // color: white !important;

        /* override properties from ngprime that we do not want */
        .p-tabview-nav-link {
          background: transparent;
          border-width: 0px;
        }

        &.hidden {
          display: none;
        }

        &:first-child {
          border-top-left-radius: $border-radius;
          a {
            border-top-left-radius: $border-radius;
          }
        }

        &:nth-last-child(2){
          border-top-right-radius: $border-radius;
          a {
            border-top-right-radius: $border-radius;
          }
        }

        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;
          font-size: 1.2rem;
          color: white;

          span.p-tabview-left-icon {
            position: absolute;
          }

          span.p-tabview-title {
            margin: 0 auto;
            text-transform: uppercase;
          }

          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }

        }

        &:hover {
          border: none;
          background-color: var(--nav-bg-hover-secondary-color);
          a {
            color: white !important;
            background: none !important;
          }
        }

        &.p-highlight {
          background-color: var(--nav-bg-color);

          .indicator {
            position: absolute;
            left: calc(50% - 0.3333rem);
            top: 0;
            width: 0;
            height: 0;
            border-left: 0.6667rem solid transparent;
            border-right: 0.6667rem solid transparent;
            border-top: 0.4167rem solid var(--base-bk-color);

            &.dark {
              border-top: 0.4167rem solid  #4E4E4E;
            }
          }

          &:hover {
            background-color: var(--nav-bg-color) !important;
          }
        }
      }
    }

    .p-tabview-panels {
      padding: 0;
      border: none;
      font-family: var(--font-family);
    }
  }
}
