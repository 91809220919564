@import 'src/colors.scss';
@import 'src/geometry.scss';

@mixin input-base {
  input {
    font-family: var(--font-family);
    width: 100%;
    display: flex;
    background-color: var(--input-transparency-color);
    border: none;
    box-shadow: 0 .1rem #00000019 inset;
    border-radius: $border-radius;
    font-size: 1.2rem;
    color: var(--base-text-color);

    &:focus, &:active {
      outline: none;
    }

    &::placeholder {
      font-style: italic;
      color: rgb(118, 118, 121);
      font-size: 1.2rem;
    }
  }

  .p-button {
    background-color: var(--base-color);
    border-color: var(--base-color);
  }
}
