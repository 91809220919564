@import "src/colors.scss";

.alg-top-right-menu {
  &.p-menu.p-menu-overlay {
    background-color: var(--nav-bk-color);
    padding-top: 0.5rem;
    width: 10rem;
    border: none;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    /* override the position which is computed by the lib */
    top: 2.9rem !important;
    left: initial !important;
    right: 0 !important;

    .p-menuitem-link {

      .p-menuitem-text, .p-menuitem-icon {
        color: white;
      }

      &:not(.p-disabled):hover {

        background: var(--hover-mask);

        .p-menuitem-text, .p-menuitem-icon {
          color: white;
        }
      }

    }

  }
}
