@import 'src/colors.scss';

.modebar-wrapper {
  height: 3.75rem;
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.1667rem;
  background-color: var(--base-color);
  padding-left: 1.6667rem;
  position: relative;

  > span {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.4167rem;
    margin-right: 0.4167rem;
    border-radius: 9rem;

    &.modebar-left-icon {
      margin-left: 0;
      margin-right: auto;
    }

    &.modebar-title {
      position: absolute;
      font-size: 1.2rem;
      font-weight: bold;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      vertical-align: middle;
    }
  }

  .right-border-adapter {
    margin-left: 0.6667rem;
    width: 1rem;
    height: 100%;
    border-bottom-left-radius: 1rem;
    background-color: var(--nav-bk-color);
  }
}

.modebar-link {
  margin-left: .4rem;
}
