@import "src/colors.scss";
@import "src/geometry.scss";

.alg-table {
  .p-datatable {
    margin-bottom: 1rem;
    font-family: var(--font-family);

    table {
      width: 100%;
    }
  }

  .p-datatable-thead > tr > th {
    position: relative;
    padding: 0 1rem;
    text-align: left;
    text-transform: uppercase;
    font-size: .9rem;
    letter-spacing: .03em;
    height: 3rem;

    &.thin {
      width: 3.3333rem;
      max-width: 3.3333rem;
    }

    // Todo: not belongs to p-datatable
    .header-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;

      p-sorticon {
        position: absolute;
        right: 0;
        top: 1.5rem;
        transform: translateY(-50%);
      }
    }

    &:focus {
      outline: transparent;
    }
  }

  .p-datatable-tbody > tr {
    border: 0.1rem solid #eeeeee;
  }

  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    border: 0.1rem solid #f0f0f0;
    color: var(--base-text-color);
    height: 4rem;
    max-height: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.2rem;
    padding: 0 1rem;

    .empty-message {
      font-style: italic;
      text-align: center;
      font-size: medium;
    }

    &.thin {
      width: 3.3333rem;
      max-width: 3.3333rem;
      text-overflow: clip;
    }
  }

  .p-checkbox {
    width: 1.0833rem;
    height: 1.0833rem;

    .p-checkbox-box {
      background-color: var(--dark-transparency-color);
      box-shadow: 0 .1rem #bbb inset;
      width: 1.0833rem;
      height: 1.0833rem;
      border: none;
      position: relative;

      &:hover {
        background-color: #d0d0d0;
      }

      &.p-highlight:not(.p-disabled):hover {
        background-color: #d0d0d0;
      }

      .p-checkbox-icon {
        overflow: visible;
        color: #505050;
        position: absolute;
        top: -0.5rem;
        left: -0.3333rem;
        font-size: 1.8333rem;
      }
    }
  }

  .p-datatable-footer {
    border: none;
    background-color: white;
    height: 5rem;
    line-height: 5rem;
    margin-bottom: -1.5rem;
    border: 0em;
    font-weight: 700;
  }

  .p-sortable-column-badge {
    display: none !important;
  }

  &.--joined-group-list {
    .p-datatable-thead > tr > th {
      p-sorticon {
        position: absolute;
        right: 10px;
        top: 1.5rem;
        transform: translateY(-50%);
      }
    }

    .p-datatable-tbody > tr:nth-child(even) {
      background-color: #fff;
    }

    .p-datatable-tbody > tr:last-child > td {
      border-bottom: none;
    }

    .p-datatable-tbody > tr > td {
      border: none;
      border-bottom: 0.1rem solid #f0f0f0;
    }

    .p-datatable-tbody > tr.p-highlight > td {
      color: #fff;
    }
  }

  &.--managed-group-list {
    .p-datatable-tbody > tr:nth-child(even) {
      background-color: #fff;
    }

    .p-datatable-tbody > tr > td {
      border: none;
      border-bottom: 0.1rem solid #f0f0f0;
    }

    .p-datatable-tbody > tr > .link {
      cursor: pointer;
    }
  }

  &.--group-manager-list {
    .p-datatable-tbody > tr.p-highlight > td {
      color: var(--base-text-color) !important;
    }
  }

  &.--grid {
    .p-datatable-thead > tr > th {
      border-top: none;
    }

    .p-datatable-tbody > tr:nth-child(even) {
      background-color: #fff;
    }

    .p-datatable-tbody > tr > td {
      border: none;
      border-bottom: 0.1rem solid #f0f0f0;
    }

    .p-datatable-tbody > tr:last-child > td {
      border-bottom: none;
    }

    .p-datatable-tbody > tr.p-highlight > td {
      color: #fff;
    }

    .p-datatable-tbody > tr {
      border: none;
    }
  }

  &.--add-content, &.--overlay-panel {
    &.--overlay-panel {
      .p-datatable-tbody > tr {
        background-color: #fff;
      }

      .p-datatable-tbody > tr:nth-child(even) {
        background-color: #fff;
      }
    }

    .p-datatable {
      margin-bottom: 0;
    }

    .p-datatable-tbody > tr {
      background-color: #EFEFEF;
      display: flex;
      border: none;
      border-bottom: $border-filet;
      margin-bottom: 5px;
      border-radius: 1rem;
      height: 3rem;
    }

    .p-datatable-tbody > tr:last-child {
      border: none;
    }

    .p-datatable-tbody > tr:nth-child(even) {
      background-color: #EFEFEF;
    }

    .p-datatable-tbody > tr > td {
      color: var(--base-text-color);
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      height: inherit;
    }
  }

  &.--pending-request {
    .p-datatable {
      .p-datatable-footer {
        background-color: transparent;
      }

      .p-datatable-thead > tr {
        > th {
          background-color: #d3dbe7;
        }

        > th:first-child {
          width: 3.3333rem;
          background-color: #d3dbe7;
        }
      }

      .p-datatable-tbody > tr {
        background-color: transparent;
        height: 13rem;

        > td {
          border-bottom-color: var(--section-border-color);

          &:last-child {
            border-left: $border-filet;
            text-align: center;
          }
        }

        &:last-child {
          border-bottom: 0.1rem solid var(--section-border-color);
        }

        &:nth-child(even) {
          background-color: transparent;
        }

        &.p-highlight {
          background-color: var(--base-color);
        }
      }
    }

    .p-datatable-frozen-view tr {
      height: 6rem;
    }

    .p-datatable-tbody > tr {
      border: none;
    }
  }

  &.--member-list {
    .p-datatable {
      .p-datatable-footer {
        background-color: transparent;
        margin-bottom: 0rem;
      }
    }
  }

  &.chapter-user-progress {
    table {
      display: inline-table;
      table-layout: auto;
    }

    .p-datatable .p-datatable-thead > tr > th {
      &:first-child {
        width: 40%;
      }
    }

    .p-datatable .p-datatable-thead > tr > th {
      &:last-child {
        width: 6rem;
      }
    }
  }
}

.summary {
  text-align: left;
  display: flex;
  align-items: center;

  p-tableheadercheckbox {
    .p-checkbox {
      display: block;
    }
  }

  .select-all {
    margin-left: 2.1rem;
    color: #9b9b9b;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .filler {
    flex: 1;
  }

  .summary-actions {
    display: flex;
    align-items: center;
    color: var(--base-color);
    font-size: 1.2rem;

    > span {
      margin-right: 1rem;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        > * {
          color: #bbb;
          pointer-events: none;
        }
      }

      span {
        margin-right: 1rem;
      }
    }
  }
}

.slanted-grid {
  .p-datatable-table {
    width: 100%;
  }
}
