.p-tooltip {

  &.permissions-dialog {
    max-width: none;

    &.p-tooltip-right {
      margin-left: .5rem;
    }
  }
}
