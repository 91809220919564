:root {
  --base-color: #4A90E2;
  --base-bk-color: #F1F2F7;
  --base-text-color: #787878;

  --secondary-color: #F5A623;

  --warning-color: #f5a623;
  --danger-color: #ff001f;

  --secondary-btn-color: #4a4a4a;

  --base-color-hover: #779CCA;
  --base-dark-color-hover: #3B73B4;
  --hover-mask: rgba(white, 0.1);

  --light-text-color: #dbdbdb;
  // $light-color: #DBE9F9;
  --light-color: #DFE7F4;
  --light-border-color: #EBEBEB;

  --dark-text-color: #4A4A4A;
  --dark-color: #33649D;
  --dark-base-color: #2E5E95;
  --dark-secondary-color: rgb(195,131,27);
  --dark-black-color: #464646;
  --dark-transparency-color: rgba(74,74,74,.1);
  --input-transparency-color: rgba(0,0,0,.05);

  --nav-bk-color: #4E4E4E;

  // Light nav theme
  --nav-bg-color: #4A90E2;
  --nav-bg-secondary-color: #3B73B4;
  --nav-bg-child-color: #3c7ac1;
  --nav-child-border-color: #2f6fc1;
  --nav-bg-handle-color: #3B73B4;
  --nav-border-color: #2e5e95;
  --nav-border-secondary-color: #4587D4;
  --nav-branch-border-color: #2e5e95;
  --nav-handle-icon-color: rgb(99, 150, 209);
  --nav-bg-hover-color: #779CCA;
  --nav-bg-hover-secondary-color: #578CCA;
  --nav-text-color: #4A90E2;

  // Dark nav theme
  --nav-dark-bg-color: #373738;
  --nav-dark-bg-secondary-color: #474747;
  --nav-dark-bg-child-color: #2c2b2b;
  --nav-dark-child-border-color: #313132;
  --nav-dark-bg-handle-color: #2C2C2C;
  --nav-dark-border-color: #313132;
  --nav-dark-border-secondary-color: #313132;
  --nav-dark-branch-border-color: rgba(255, 255, 255, .6);
  --nav-dark-handle-icon-color: rgba(255, 255, 255, .6);
  --nav-dark-bg-hover-color: #373738;
  --nav-dark-bg-hover-secondary-color: #676767;
  --nav-dark-text-color: #464646;

  // Section colors
  --section-color: #DFE7F4;
  --section-border-color: #D1D9E5;
  --section-danger-color: #F2D9E0;
  --section-danger-handle-color: #FF001F;
  --section-danger-handle-border-color: #B20015;
  --section-warning-color: #fde8c8;
  --section-warning-handle-color: #f9b42c;
  --section-warning-handle-border-color: #ba861c;

  //Session colors
  --session-left-view-color: #F2F2F2;
  --session-right-view-color: #E5E5E5;

  // Notification colors
  --notify-error-color: #FF001F;

  --lock-border-color: #c1c2c6;
}

