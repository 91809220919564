/* You can add global styles to this file, and also import other style files */
@import 'src/colors.scss';
@import 'src/geometry.scss';
@import 'assets/scss/helpers';
@import 'assets/scss/components/page-header';
@import 'assets/scss/components/table';
@import 'assets/scss/components/editable-table';
@import 'assets/scss/components/error-message';
@import 'assets/scss/components/data-list-container';
@import 'assets/scss/components/mode-bar';
@import 'assets/scss/components/language-picker-dropdown';
@import 'assets/scss/components/calendar';
@import 'assets/scss/components/duration';
@import 'assets/scss/components/input-number';
@import 'assets/scss/components/tab-rounded';
@import 'assets/scss/components/tab-nav';
@import 'assets/scss/components/tab-left-nav';
@import 'assets/scss/components/tree-nav';
@import 'assets/scss/components/login-button';
@import 'assets/scss/components/dropdown';
@import 'assets/scss/components/switch';
@import 'assets/scss/components/top-right-menu';
@import 'assets/scss/components/top-right-menu-button';
@import 'assets/scss/components/button';
@import 'assets/scss/components/button-icon';
@import 'assets/scss/components/messages';
@import 'assets/scss/components/permissions-edit-dialog';
@import 'assets/scss/components/propagation-edit-menu';
@import 'assets/scss/components/group-join-by-code';
@import 'assets/scss/components/block-ui';
@import 'assets/scss/components/tooltip';
@import 'assets/scss/components/path-suggestion-overlay';

/* Custom themes */
@import 'assets/scss/themes/coursera-pt';

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  font-size: 1.2rem;
  margin: 0;
  background-color: var(--base-bk-color);
  min-height: 100%;

  --font-family: "titillium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body .p-component {
  font-family: var(--font-family);
  font-size: inherit;
}

.cdk-global-overlay-wrapper {
  background-color: #FFFC;
}

.cdk-overlay-connected-position-bounding-box {
  margin-bottom: -2rem;
}

.user-progress-overlay-panel {
  background-color: transparent;
  box-shadow: none;
  min-height: unset !important;
  border: none;

  &:before {
    border-bottom-color: #295485;
    border-width: 0.4167rem;
  }

  &:after {
    border: none;
  }

  &.p-overlaypanel-flipped {
    &:before {
      border-top-color: var(--base-color);
    }

    &:after {
      border: none;
    }
  }

  .p-overlaypanel-content {
    padding: 0;
  }
}

.p-tooltip.tooltip-custom {

  .p-tooltip-text {
    background-color: var(--base-color);
    border-radius: 1rem;
    padding: 1rem 2rem;
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: var(--base-color);
    }
  }

}

.left-nav-theme {
  &.dark {
    --nav-bg-color: var(--nav-dark-bg-color);
    --nav-bg-secondary-color: var(--nav-dark-bg-secondary-color);
    --nav-bg-child-color: var(--nav-dark-bg-child-color);
    --nav-child-border-color: var(--nav-dark-child-border-color);
    --nav-bg-handle-color: var(--nav-dark-bg-handle-color);
    --nav-border-color: var(--nav-dark-border-color);
    --nav-border-secondary-color: var(--nav-dark-border-secondary-color);
    --nav-branch-border-color: var(--nav-dark-branch-border-color);
    --nav-handle-icon-color: var(--nav-dark-handle-icon-color);
    --nav-bg-hover-color: var(--nav-dark-bg-hover-color);
    --nav-bg-hover-secondary-color: var(--nav-dark-bg-hover-secondary-color);
    --nav-text-color: var(--nav-dark-text-color);
  }
}

.common-confirm-popup {
  margin-left: 2rem;
  margin-right: 2rem;
}

@media screen and (max-width: 800px) {
  html { font-size: 55.66%; }
}
@media screen and (min-width: 1200px) {
  html { font-size: 67.87%; }
}
@media screen and (min-width: 1400px) {
  html { font-size: 73.97%; }
}
@media screen and (min-width: 1600px) {
  html { font-size: 80.08%; }
}
@media screen and (min-width: 1920px) {
  html { font-size: 89.84%; }
}
@media screen and (min-width: 2200px) {
  html { font-size: 98.39%; }
}
@media screen and (min-width: 2400px) {
  html { font-size: 104.49%; }
}
@media screen and (min-width: 2700px) {
  html { font-size: 113.65%; }
}
@media screen and (min-width: 3000px) {
  html { font-size: 122.80%; }
}
@media screen and (min-width: 3300px) {
  html { font-size: 131.96%; }
}
@media screen and (min-width: 3600px) {
  html { font-size: 141.11%; }
}
@media screen and (min-width: 4000px) {
  html { font-size: 153.32%; }
}
