@import 'src/colors.scss';

.alg-button-icon {
  border: none;
  cursor: pointer;

  &.base-color {
    i {
      color: var(--base-color);
    }
  }

}
