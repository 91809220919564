/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 14, 2022 */



@font-face {
    font-family: 'Source Sans Pro';
    src: url('sourcesanspro-bold-webfont.woff2') format('woff2'),
         url('sourcesanspro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Source Sans Pro';
    src: url('sourcesanspro-italic-webfont.woff2') format('woff2'),
         url('sourcesanspro-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Source Sans Pro';
    src: url('sourcesanspro-regular-webfont.woff2') format('woff2'),
         url('sourcesanspro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Source Sans Pro';
    src: url('sourcesanspro-light-webfont.woff2') format('woff2'),
         url('sourcesanspro-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
