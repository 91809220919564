alg-group-join-by-code {
  .submit-duration-button {
    border-radius: $border-radius;
  }

  .alg-button {
    &.p-button {
      height: 2.574rem;
    }
  }
}

