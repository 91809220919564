@import 'src/colors.scss';

.tri-tag {
  position: absolute;
  top: -.8rem;
  left: 3rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: .8rem solid var(--light-color);
}

.alg-link {
  font-family: var(--font-family);
  text-decoration: none;

  color: var(--base-text-color);

  cursor: pointer;

  &:hover {
    color: var(--base-color);
  }

  &.disabled {
    cursor: auto;
    pointer-events: none;

    &:hover {
      color: initial;
    }
  }

  &.base-color {
    color: var(--base-color);
  }

  &.white-color {
    color: #fff;
  }

  &.underline {
    text-decoration: underline;
  }

  &.hover-underline {
    &:hover {
      text-decoration: underline;
    }
  }

  &.dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.flex-1 {
  flex: 1;
}

.uppercase {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.alg-error-text {
  color: var(--base-color);
  font-size: 1.6rem;
  text-align: center;
}

.alg-base-text-color {
  color: var(--dark-text-color);
}
