/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 5, 2020 */



@font-face {
    font-family: 'titillium';
    src: url('titilliumweb-bold-webfont.woff2') format('woff2'),
         url('titilliumweb-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'titillium';
    src: url('titilliumweb-italic-webfont.woff2') format('woff2'),
         url('titilliumweb-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}




@font-face {
    font-family: 'titillium';
    src: url('titilliumweb-light-webfont.woff2') format('woff2'),
         url('titilliumweb-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'titillium';
    src: url('titilliumweb-regular-webfont.woff2') format('woff2'),
         url('titilliumweb-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
